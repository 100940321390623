.categories-container {
	.actions-container {
		display: flex;
		align-items: center;
		gap: 35px;
		margin-left: 20px;
		margin-top: 20px;
	}

	.category-list {
		height: 100%;
		display: flex;
		margin: 0;
		align-items: center;
		gap: 35px;
	}

	.category-item {
		color: #7a7a7a;
	}

	.active {
		background-color: #e4005b;
		color: #fff;
		padding: 10px 24px;
		border-radius: 15px;
	}
}
