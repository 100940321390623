.sidebar {
	width: 200px;

	.sidenav {
		height: 100%;
		overflow: auto;
	}

	.sidebar-header {
		// height: 80px;
		padding: 15px 20px;
	}

	.sidebar-menu .sidebar-icon {
		position: absolute;
		top: 17px;
		left: 20px;
		margin-right: 20px;
	}
}
