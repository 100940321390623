.signup__page {
	min-height: 100vh;
	display: flex;

	.showcase {
		flex: 1.5;
		min-height: 150vh;
		background-image: url("../../assets/signup_showcase.jpg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);
	}

	.heading {
		font-weight: 400;
		text-align: center;
		position: relative;
		// bottom: 20px;
	}

	.content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 45%;
		// margin: 0px 40px;
	}

	.signup__form {
		width: 65%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
	}

	.input_field-container {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.terms_of_agreement_wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		width: 100%;
		margin-top: 20px;
	}

	.terms_of_agreement_text {
		font-size: 12px;
		text-align: center;
	}

	.login_link_text {
		margin-top: 10px;
		font-size: 14px;
	}
}
