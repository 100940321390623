.forgot_password {
	height: 100vh;
	position: relative;

	.header {
		display: flex;
		align-items: center;
		padding: 10px 16px;
		box-shadow: -1px 1px 8px rgb(0 0 0 / 0.2);
		// box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

		// background-color: #fb8500;
		// border-bottom: 1px solid rgb(235, 235, 235);
		// #ffb703, #fb8500

		.logo {
			color: #000;
			text-decoration: none;
			position: relative;
			left: 30px;
			// font-weight: 400;
			// color: #fff;
		}
	}

	.content {
		position: absolute;
		top: 60%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 35%;
		height: 60%;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.title {
		color: $text-primary-color;
		text-align: center;
	}

	.helper-text {
		color: $text-opacity-color;
	}

	.form {
		align-self: center;
		width: 75%;
		margin-top: 40px;
		display: flex;
		flex-direction: column;
	}

	.button {
		margin-top: 20px;
	}

	.back_button {
		text-decoration: none;
		margin-top: 6px;
		font-size: 14px;
		text-align: end;
	}

	.copyright_wrapper {
		position: absolute;
		bottom: 10px;
		display: flex;
		justify-content: center;
		width: 100%;
	}
}
