@import "~normalize.css/normalize.css";

* {
	padding: 0;
	margin: 0;
	list-style: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	min-height: 100vh;
	font-family: "Poppins", serif;
}

// body {
// 	margin: 0;
// 	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
// 		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
// 		"Helvetica Neue", sans-serif;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// }

// code {
// 	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
// 		monospace;
// }
