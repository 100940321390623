#menu-manager {
	height: 100%;

	.content {
		padding: 20px;
	}

	.menu-table-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		margin-top: 40px;
	}

	.empty-menu-table {
		justify-items: center;
		margin-top: 35px;

		.text-wrapper {
			margin-top: 10px;
		}
	}
}
