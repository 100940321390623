@tailwind base;
@tailwind components;
@tailwind utilities;

@import "custom";

// Base imports
@import "./base/_base-dir";

// Abstracts imports
@import "./abstracts/_abstracts-dir";

// Layouts imports
@import "./layouts/_layouts-dir";

// Components imports
@import "./components/_components-dir";

// pages imports
@import "./pages/_pages-dir";

#main {
	min-height: $content-height;
	background-color: $layout-bg;
}

// .public_container {
// 	position: relative;
// }
