// .app_init-loader {
// 	display: block;
// }

.app-loader {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
