#not_found {
	// background-color: rgb(243, 244, 247);
	background-color: #f3f4f7;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.content {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 15px;
	}

	.status_code {
		color: #212631;
		font-size: 4rem;
		font-weight: 300;
	}

	.main_text {
		font-size: 1.40625rem;
		font-weight: 500;
		color: #212631;
	}

	.helper_text {
		font-size: 17px;
		color: rgba(37, 43, 54, 0.681);
	}

	.back_button {
		text-decoration: none;
		background-color: $text-primary-color;
		padding: 4px 12px;
		color: #fff;
		border-radius: 4px;
	}
}
