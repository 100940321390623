.app__layout {
	display: flex;
	min-height: 100vh;

	#main {
		width: 100%;
		min-height: $content-height;
	}

	.content-container {
		padding: 10px;

		// min-height: $content-height;

		.heading {
			margin-bottom: 45px;
		}
	}

	.panel-item {
		background-color: #fff;
	}
}
