.rs-dropdown-menu {
	.user-dd-header-text {
		// color: var(--mantine-color-scheme);
	}

	.header-text-helper {
		font-weight: 600;
		// opacity: 0.9;
	}

	.user-dd-item {
		color: var(--mantine-color-scheme);
		width: 100%;
		display: flex;
		align-items: center;
		gap: 8px;
		text-decoration: none;
	}
}
