.rs-drawer-header {
	padding: 15px;

	.rs-drawer-title {
		position: relative;
	}

	.rs-drawer-header-close {
		position: initial;
		left: 0;
	}
}
