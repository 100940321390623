.store_hours {
	.store_hours-list {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.weekday-item {
			display: flex;
			justify-content: space-between;

			.checkbox-label {
				display: flex;
				align-items: center;
				gap: 5px;

				input {
					height: 20px;
					width: 20px;
				}
			}

			.hour_selectors-group {
				display: flex;
				align-items: center;
				gap: 15px;

				.selects {
					display: flex;
					align-items: center;
					gap: 25px;
				}

				.hour-selection-dd {
					width: 150px;
				}

				.add-icon {
					cursor: pointer;
				}
			}
		}
	}
}
